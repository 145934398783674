@import url('https://fonts.googleapis.com/css?family=Inconsolata');

body, html {
  margin: 0;
  padding: 0;
  /* user-select: none; */
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
body {
  position: fixed;
  touch-action: none;

}


.App {
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  touch-action: none;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  position: absolute;
  border-color: red;
  background-color: #222;
  top: 450px;
  padding: 20px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:visited {
  text-decoration: none;
  color: black;
}

html {
  touch-action: none;

}

.switch-wrapper {
    width: 100vw;
  height: 100vh;
}

.horizontalLock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
}