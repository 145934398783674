/* filter.css */

.filter-canvas {
    z-index: 51;
    position: relative;
    /* width: 33vw;
    height: 33vh;
    margin: 1vh 1vw;
    position: absolute; */
    top: 0%;
    background-color: lightblue;
    image-rendering: -moz-crisp-edges;
    /* Firefox */
    image-rendering: -webkit-crisp-edges;
    /* Webkit (Safari) */
    image-rendering: pixelated;
    /* Chrome */
    flex-shrink: 0;
    /* Prevent canvas from shrinking */
    height: 35vh;
    /* Set fixed height */
    width: 100%;
    /* Ensure canvas takes full width */
}

.canvas-container {
    flex-shrink: 0;
    /* Prevent container from shrinking */
}

.edit-filter-container {
    width: 35vw;
    /* height: 35vh; */
    /* Remove height to allow content to adjust */
    display: flex !important;
    flex-direction: column;
    z-index: 50;
}

#FilterPresets {
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 5.5px 10.5px;
}