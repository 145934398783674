.volume-container {
    position: absolute!important;
    /* right: 362.17px!important; */
}

.volume-controls {
    justify-content: center;
    padding-left: 5px!important;
    padding-right: 5px!important;
}

.volume-close-menu {
    position: absolute;
}