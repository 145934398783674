.tuning-container {
  /* width: 46%; */
  display: flex;
}

.tuning-close-menu{
  display: flex!important;
  justify-content: flex-end;
  align-items: center;
}
#edit-scales-button-tuning {
  /* position: absolute; */
  /* right: 10%; */
  /* bottom: 0%; */
  padding: 5.5px 21px;
  margin: -5px auto -3px auto;
}
.scales-bottom-tuning {
  width: 100%;
  display: flex;
  padding: 0.5em 0.5em;
  align-items: center;
}
