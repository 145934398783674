.effect-module {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-right: 0px;
}

.effect-name {
  line-height: normal;
  white-space: nowrap;
}

.effect-on {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.effect-controls {
  display: flex;
  /* text-align: left; */
  flex-direction: column;
}

.effect-control {
  display: flex;
  flex-direction: column;
  /* margin-right: 10px; */
  margin-top: 2px;

}

#text {
  font-size: 14px;
  margin: 0px;
  margin-bottom: -8px;
  margin-top: -8px;
}

/* This is the tooltip for the slider. Play around with this if you want to put int on the bottom */
.rangeslider.rangeslider-horizontal .rangeslider__handle .rangeslider__handle-tooltip {
  bottom: -55px!important;
  top: auto!important;
}

/* this is the arrow of the tooltip */
.rangeslider.rangeslider-horizontal .rangeslider__handle .rangeslider__handle-tooltip:after {
  border-bottom: 8px solid rgba(0, 0, 0, 0.8);
  border-top: none;
  top: -8px;
}