.scales-container {
  justify-content: center;
}

#scaleToggleButton {
  margin: 0;
  font-size: 1rem;
}

.ui.tabular.menu-menu .item.scales-container {
  padding: 0.1em 0.5em;
}

.ui.tabular.menu-menu .scales-container .item.edit-scales-container {
  display: flex!important;
  justify-content: space-around;
  flex-direction: row;
  z-index: 100;
  padding: 0.5em 0px;
}

@media screen and (max-width: 1040px) {
  .ui.tabular.menu-menu .item.edit-scales-container {
    padding-left: 4px;
    padding-right: 4px;
  }
}

@media screen and (max-width: 970px) {
  .ui.dropdown i.icon.dropdown {
    margin-left: 4px;
  }
}

.note {
  border: solid black;
  border-width: 0px;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  font-size: 1em;
  user-select: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: lightgrey;
}

.root {
  border: solid black;
  border-width: 0;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  font-size: 1em;
  user-select: none;
  padding: 10px;
  cursor: pointer;
  -webkit-box-shadow: inset 0px 0px 0px 2px #56caff;
  -moz-box-shadow: inset 0px 0px 0px 2px #56caff;
  box-shadow: inset 0px 0px 0px 2px #56caff;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.activeNote {
  background-color: #7BB242;
}
.custom {
  -webkit-box-shadow: inset 0px 0px 0px 2px rgb(255, 168, 5);
  -moz-box-shadow: inset 0px 0px 0px 2px rgb(255, 168, 5);
  box-shadow: inset 0px 0px 0px 2px rgb(255, 168, 5);
}
