.fullscreenbutton {
    position: absolute;
    bottom: 0%;
    right: 0%;
    z-index: 51;
    opacity: 0.5;
}

canvas {
    position: absolute;
    width: 100%;
    height: calc(100% - 105px);
    top: 105px;
    z-index: 9;
    flex: 1;
    left: 0%;
    touch-action: none;
    overflow: hidden;
}