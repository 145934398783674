@import url(https://fonts.googleapis.com/css?family=Inconsolata);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body, html {
  margin: 0;
  padding: 0;
  /* user-select: none; */
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
body {
  position: fixed;
  touch-action: none;

}


.App {
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  touch-action: none;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  position: absolute;
  border-color: red;
  background-color: #222;
  top: 450px;
  padding: 20px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:visited {
  text-decoration: none;
  color: black;
}

html {
  touch-action: none;

}

.switch-wrapper {
    width: 100vw;
  height: 100vh;
}

.horizontalLock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
}
.fullscreenbutton {
    position: absolute;
    bottom: 0%;
    right: 0%;
    z-index: 51;
    opacity: 0.5;
}

canvas {
    position: absolute;
    width: 100%;
    height: calc(100% - 105px);
    top: 105px;
    z-index: 9;
    flex: 1 1;
    left: 0%;
    touch-action: none;
    overflow: hidden;
}

.bg-black {
  background-color: #000000;
}
#instructions-bg {
  background-color: rgba(255,255,255,0.2);
  font-family: 'Inconsolata';
  font-size: 20px;
  position: absolute;
  width: auto;
  height: auto;
  margin: 0 auto;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  z-index: 99;
  -webkit-user-select: none;
          user-select: none;
}
#instructions {
  font-family: 'Inconsolata';
  font-size: 24px;
  color: #ffffff;
  margin: 0 12px 12px 12px;
}
#close-instr {
  text-align: right;
}
#outside-instr {
  background: rgb(255, 12, 12);
  position: absolute;
  opacity: 0;
  z-index: 98;
  width: 100vw;
  height: 100vh;
  transform: translateX(-50%);
}
.closing {
  
  animation-iteration-count: 1;
}

.flashing {
  animation: blinker 1.5s;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes fadeout-instr {

  60% { opacity: 1; }
  100% { opacity: 0; }
}

/* Instructions for microphone styling */
.modal{
  background-color: white;
  position: absolute;
  z-index: 1000;
  width: auto;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  top: 12%;
  border-radius: 10px;
  box-shadow: 3px 3px 5px 3px rgba(0,0,0,0.75);
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
}
.modal .row {
  padding: 4px 4px 4px 4px;
  display: flex;
  flex-direction: row;
}
.modal .column {
  padding: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
}
.modal .header {
  position: relative;
  height: 100%;
  margin: 3%;
}
.modal .title {
  width: 12rem;
  font-size: 1.5rem;
}
.modal .title .subtitle {
  width: 12rem;
  font-size: 1.25rem;
}
.modal .modalBtn {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  /* margin: 3%; */
  bottom: 0%;
}
.modal .step {
  width: 33vw;
}
.modal .img {
  margin: auto;
  max-height: auto;
  width: 20vw;
}

.headphone-mode-button {
  position: absolute;
  left: 4%;
  top: 96px;
  margin: 2px 2px!important;
  min-width: 3%;
  z-index: 21;
  background-color: #ABE2FB!important;
  /* opacity: 0.5; */
  border: 2px solid #ABE2FB!important;
  border-radius: 3rem!important;
}
.headphone-slash-icon {
  height: .85714286em;
  width: 1.18em;
}

.midi-logo {
  height: 1.2em;
  width: 1.25em;
  /* height: 1.15em; */
  /* width: 1.15em; */
}

.color-map-container {
  width: 15%;
  position: absolute;
  right: 15%;
  bottom: 0%;
  padding: 2px;
  z-index: 21;
  -webkit-user-select: none;
          user-select: none;
  font-family: 'Lato';
  /* font-size: 16px; */
  /* font-family: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"; */
}

.color-map {
  border: 1px solid white;
  background: linear-gradient(to right, black 15%, blue 30%, green, red);
  width: 100%;
  height: 1em;
  position: relative;
}

.color-map-text {
  color: white;
  display: flex;
  justify-content: center;
  font-family: 'Lato';
  /* background-color: black; */
}

.color-map-labels {
  color: white;
  display: flex;
  justify-content: space-between;
  font-family: 'Lato';
  position: absolute;
  left: 0%;
  top: 50%;
  width: 100%;
  padding: 0px 5px;
  z-index: 10;
  font-size: 0.75em;
  /* background-color: black; */
}

.scales-snackbar {
  z-index: 60;
  position: fixed!important;
  bottom: 0;
  left: 50%;
  margin: 0!important;
  padding: 2vh!important;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  opacity: 0;
  transform: translateX(-50%);
  animation: fades 1s ease-in-out 0.2s alternate;
  animation-duration: 2s;
}

@keyframes fades {
  50% {
    opacity: 1;
  }
}

/* use this on all the buttons on the top row that are within the canvas */
.canvas-btn {
  top: 110px;
}

.pause-button {
  position: absolute;
  left: 0%;
  margin: 2px 2px!important;
  min-width: 3.1%;
  max-width: 4%;
  z-index: 21;
  /* opacity: 0.5; */
  border: 2px solid #ABE2FB!important;
  background-color: #ABE2FB!important;
  border-radius: 3rem!important;
}

#microphone-mode-button {
  position: absolute;
  left: 4%;
  margin: 2px 2px!important;
  min-width: 3%;
  z-index: 21;
  /* opacity: 0.5; */
  border: 2px solid #ABE2FB!important;
  border-radius: 3rem!important;
  background-color: #ABE2FB!important;
}

.info-button {
  position: absolute;
  left: 16%;
  margin: 2px 2px!important;
  min-width: 3%;
  z-index: 21;
  background-color: #ABE2FB!important;
  /* opacity: 0.5; */
  border: 2px solid #ABE2FB!important;
  border-radius: 3rem!important;
  padding: 11px!important;
}
.info-button-icon {
  margin: 0px!important;
}

.midi-button {
  position: absolute;
  left: 12%;
  margin: 2px 2px!important;
  min-width: 3%;
  z-index: 21;
  background-color: #ABE2FB!important;
  /* opacity: 0.5; */
  border: 2px solid #ABE2FB!important;
  border-radius: 3rem!important;
  padding: 0.6714rem 0.75714rem!important;
  /* padding: 2px!important; */
  /* height: 50px; */
}

.file-upload-button {
  position: absolute;
  left: 8%;
  margin: 2px 2px!important;
  min-width: 3%;
  z-index: 21;
  background-color: #ABE2FB!important;
  /* opacity: 0.5; */
  border: 2px solid #ABE2FB!important;
  border-radius: 3rem!important;
  padding: 11px!important;
  /* padding: 0.6714rem 0.75714rem!important; */
  /* padding: 2px!important; */
  /* height: 50px; */
}

.web-cam-button {
  position: absolute;
  left: 12%;
  margin: 2px 2px!important;
  min-width: 3%;
  z-index: 21;
  background-color: #ABE2FB!important;
  /* opacity: 0.5; */
  border: 2px solid #ABE2FB!important;
  border-radius: 3rem!important;
  padding: 11px!important;
}

.playback-controls-container {
  position: absolute;
  left: 20%;
  top: 110px;
  background-color: rgb(170,225,251);
  z-index: 21;
  color: white;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  min-width: 20%;
  align-items: center;
  margin: 2px 2px;
  padding: 11px;
}

.audio-file-play {
  height: 100%!important;
  cursor: pointer;

}

.audio-file-progress {
  width: 100%;
  flex: 1 1;
  margin: 0px 15px!important;
}

.bar {
  min-width: 0!important;

}

.audio-file-delete {
  height: 100%!important;
  cursor: pointer;

}



.pulsing {
  animation: shadow-pulse 1s;
  animation-iteration-count: 20;
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(255, 255, 255, 0);
  }
}

.tuning-canvas {
  z-index: 20;
}

.osc-canvas {
  z-index: 20;
}
.frequency-label {
  z-index: 25;
  position: absolute;
  left: 1%;
  bottom: 1%;
  background-color: white;
  padding: 2em;
  border-radius: .58571429rem;
  padding: 1em 1.5em;
  outline: none;
  line-height: 1em;
}

.App {
  text-align: center;
}

.menu-container {
  position: absolute;
  left: 0%;
}

.menu-title {
  font-size: 24px;
  align-self: center;
  text-align: center;
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  -webkit-user-select: none;
          user-select: none;
}

.menu-header {
  font-weight: 700;
  font-size: 14px;
}

.ui.tabular.menu .item {
  background: 0 0;
  border-bottom: none;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 2px solid transparent;
  padding: .1em 1.42857143em;
  color: rgba(0,0,0,.87);
}

.menu-menu {
  height: 105px;
  /* min-height: 90px;
  max-height: 10vh; */
  margin: 0!important;
  border-bottom: 1px solid black!important;
  width: 100vw!important;
}

.menu-container .menu-menu.menu .item.tab-item {
  border-left-color: rgba(0,0,0,.1);
  border-right-color: rgba(0,0,0,.1);
}

.menu-container .menu-menu.menu .item.tab-item.active {
  box-shadow: inset 0px 0px 5px 2px rgba(34,36,38,.1);
}

@media screen and (max-width: 970px) {
  .menu-container .item.tab-item {
    padding-left: 2px!important;
    padding-right: 2px!important;
  }
}

@media screen and (max-width: 1241px) {
  .ui.tabular.menu-menu.menu .item.scale-menu {
    padding: 0px!important;
  }
}

@media screen and (max-width: 1040px) {
  .menu-container .ui.tabular.menu .item.tab-item {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.ui.tabular.menu .item.tab-item.settings-ctrl-btn {
  padding-left: 10px;
  padding-right: 10px;
}

.rangeslider__handle {
  outline: none;
}

.slider {
  min-width: 100px;
}

.flex {
  display: flex;
  flex-direction: column;
}

.item {
  font-size: 16px!important;
}

.icon-button {
  background: none!important;
}

/* .function-switch-button-container {
  padding-left: 0!important;
}

.function-switch-button {
  border: 2px solid #9ad5f7;
  outline: none;
  border-radius: .28571429rem;
  padding: .5em 1.2em .5em;
  line-height: 1em;
  background-color: white;
  color: #9ad5f7;
  font-weight: 700;
  font-style: normal;
  font-size: 1em;
  min-height: 1em;
  vertical-align: baseline;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}

.function-switch-button:hover {
  outline: none;
  cursor: pointer;
}

.function-switch-button:active {
  background-color: #49a3cc;
  border: 2px solid #49a3cc;
  color: white;
  outline: none;
  cursor: pointer;
}

.tuning-button {
  border: 2px solid #ff8177;
  outline: none;
  border-radius: .28571429rem;
  padding: .5em 1.2em .5em;
  line-height: 1em;
  background-color: white;
  color: black;
  font-weight: 400;
  line-height: 1;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 16px;
  min-height: 1em;
  vertical-align: baseline;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  user-select: none;
} */

/* .freq-button {
  border: 2px solid #ff695e;
  outline: none;
  border-radius: .28571429rem;
  padding: .5em 1.2em .5em;
  line-height: 1em;
  background-color: white;
  color: #ff695e;
  font-weight: 700;
  font-style: normal;
  font-size: 1em;
  min-height: 1em;
  vertical-align: baseline;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}

.freq-button:hover {
  outline: none;
  cursor: pointer;
}

.freq-button:active {
  background-color: #ff8177;
  border: 2px solid #ff8177;
  color: white;
  outline: none;
  cursor: pointer;
} */

/* .freq-button {
  background-color: white!important;
  color: black!important;
  font-weight: 400!important;

} */

/* .tuning-button:hover {
  outline: none;
  cursor: pointer;
} */

.ui.slider.checkbox input:focus:checked~.box:before, .ui.slider.checkbox input:focus:checked~label:before {
  background-color: #ABE2FB!important;
}

.ui.slider.checkbox input:checked~.box:before, .ui.slider.checkbox input:checked~label:before {
  background-color: #ABE2FB!important;
}

.ui.slider.checkbox input:focus~.box:before, .ui.slider.checkbox input:focus~label:before {
  background-color: #7CB342!important;
}

.ui.slider.checkbox .box:before, .ui.slider.checkbox label:before {
  background-color: #7CB342!important;
}
.ui.toggle.button {
  vertical-align: middle;
}
.ui.toggle.button.active {
  /* margin-right: 6.325px;
  margin-left: 2.325px; */
  /* Active color */
  background-color: #7CB342!important;
}

.hidden {
  display: none;
}

.item.scale-menu .scale-adjust {
  box-shadow: 0px 0px 0px 1px rgba(34,36,38,.1)!important;
  padding: 0.1em 0.25em;
  min-width: 196px;
}
@media screen and (max-width: 970px) {
  .ui.menu .item.scale-menu .scale-adjust {
    padding: 0px!important;
  }
}


.no-margin {
  margin: 0!important;
  padding: 0!important;
}



/* @media screen and (max-width: 1200px)  {
  .microphone-positioning {
      left: 15%!important;

  }
}

.microphone-positioning {
  position: absolute;
  left: 25%;
} */

/* .app-bar-overlay{
  position: absolute;
  z-index: 40;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0%;
  top: 0%;
}

.app-bar-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 300px;
  z-index: 50;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
  0px 16px 24px 2px rgba(0, 0, 0, 0.14),
  0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.app-bar-title-container {
  height: 15vh;
  background-color: white;
  display: flex;
  align-items: center;
  position: relative;
}

.app-bar-title {
  padding-left: 1rem;
  text-align: left;
  font-size: 20px;
  color: grey;
}

.app-bar-about {
  position: absolute;
  right: 2%;
  bottom: 2%;
}

.app-bar-hr {
  height: 1px;
  margin: 0;
  border: none; 
  background-color: rgba(0, 0, 0, 0.12);
}

.app-bar-buttons-container {
  display: flex;
  flex-direction:column;
  width: 300px;
  margin-top: 2%;
}

.app-bar-button {
  text-align: left;
  width: 300px;
  margin-bottom: 1rem;
  cursor: pointer;
  padding: 1rem 1rem;
  color: black;
  font-size: 2em;
  outline: none;
  line-height: 1em;
  background-color: white;
  border: 0px;
}

.app-bar-button:hover {
  background-color: #F5F5F5;
}

.app-bar-chevron {
  position: absolute;
  right: 0%;
} */
.app-bar-dropdown-container {
  padding-left: 0px !important;
  z-index: 40;
  padding-right: 0px!important
}

.app-bar-dropdown {
  font-size: 1.25rem;
  min-width: 9rem!important;
  border: none!important;
  min-height: 1rem!important;
  -webkit-user-select: none;
          user-select: none;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  font-weight: 700!important;
}

@media screen and (max-width: 1040px) {
  .ui.selection.dropdown.app-bar-dropdown {
    padding-left: 4px!important;
    padding-right: 0px!important;
  }
  .ui.selection.dropdown.app-bar-dropdown>i.dropdown.icon {
    padding-left: 4px!important;
    padding-right: 4px!important;
  }
}

/* .app-bar-dropdown-menu {
  height: 225%;
  border: none!important;
} */

.ui.selection.active.dropdown .menu {
  border: none !important;
}

.slider-label {
  margin-right: 0.5rem;
}



.menu-pane-container {
  padding: 0;
  margin: 0!important;
  border: 0!important;
}

/* @media screen and (min-width: 1215px)  {
  .multi-slider-container {
    width: 200px;
  }
}
@media screen and (max-width: 1215px)  {
  .multi-slider-container {
      width: 150px;
  }
}
@media screen and (max-width: 1165px)  {
  .multi-slider-container {
      width: 110px;
  }
} */

.multi-slider-container {
  width: 12vw;
  max-width: 200px;
  padding-left: 2px;
}


.graph-control-menu-container {
  padding-left: 0px!important;
  padding-right: 5px!important;
}

.multi-slider {
  width: 80%!important;
  margin: 12px 15px 15px 15px;
  display: flex;
  /* padding: 0!important; */
  height: 14px !important;
  transform: translateX(-4px)
}

/* .graph-limit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  padding: 0px 5px!important;
} */

.multi-slider * {
  height: 12px!important;
}

.rc-slider-handle {
  width: 30px!important;
  height: 30px!important;
  top: 50%!important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
  border: 1px solid #ccc!important;
  transform: translate3d(-50%, -30%, 0)!important;
  margin: 0!important;
}
.rc-slider-handle::after {
  content: ' ';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  background-color: #dadada;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
}
/* .rc-slider-handle:hover {
  background-color: white;
}
.rc-slider-handle:active {
  box-shadow: 0 0 5px #d8ff7b!important;
} */

.rc-slider-track {
  background-color: #7CB342!important;
}
.rc-slider-step {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 10px !important;
}
.rc-slider {
  height: 0px!important;
  padding: 2px 0!important;
}

.resolution-input-container {
  margin-top: 20%; 
}

.resolution-container {
display: flex;
justify-content: center;
align-items: center;
}

.resolution-input {
  width: 40%;
  margin: 0!important;
}

.graph-preset-container {
  display: flex;
  flex-direction: column; 
}

.graph-preset-container .ui.radio.checkbox {
  padding-top: 2px;
  padding-bottom: 2px;
}

.graph-container {
  /* position: absolute!important; */
}

#logScaleBtn {
  font-size: 1rem!important;
}

.effect-module {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-right: 0px;
}

.effect-name {
  line-height: normal;
  white-space: nowrap;
}

.effect-on {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.effect-controls {
  display: flex;
  /* text-align: left; */
  flex-direction: column;
}

.effect-control {
  display: flex;
  flex-direction: column;
  /* margin-right: 10px; */
  margin-top: 2px;

}

#text {
  font-size: 14px;
  margin: 0px;
  margin-bottom: -8px;
  margin-top: -8px;
}

/* This is the tooltip for the slider. Play around with this if you want to put int on the bottom */
.rangeslider.rangeslider-horizontal .rangeslider__handle .rangeslider__handle-tooltip {
  bottom: -55px!important;
  top: auto!important;
}

/* this is the arrow of the tooltip */
.rangeslider.rangeslider-horizontal .rangeslider__handle .rangeslider__handle-tooltip:after {
  border-bottom: 8px solid rgba(0, 0, 0, 0.8);
  border-top: none;
  top: -8px;
}
.menu-pane-container {
  z-index: 22;
  /* opacity: 0.85; */
  padding: 0.5em!important;
}

.menu-pane {
  margin: 0!important;
}

/* .sound-pane-container {
  min-width: 630px;
} */


.timbre-text {
  color: red;
  text-align: center;
}

.timbre-dropdown {
  margin-top: 8%!important;
  min-width: 100px!important;
}

.quantize-margin {
  margin-top: 8%!important;  
}

/* .sound-toggle-container {
  margin: 4% 0;
} */

.harmonics-slider {
  width: 100%;
  /* margin-bottom: 0%!important; */
}

.adsr-slider {
  margin-right: 10px;
}

.adsr-canvas{
  position: relative!important;
  top:0%!important;
  width: 275px;
}

.adsr-container {
  background-color: #282828;
  border: 1px solid #282828;
  border-radius: 0.5em;
  margin-top: 2px;
}

/* .scales-checkbox {
  margin: 10px 0px 10px 0px;
} */

.scales-bottom {
  width: 100%;
  display: flex;
  padding: 0.1em 0.5em
}

#edit-scales-button {
  margin-top: 5px;
  padding: 5.5px 21px;
  font-size: 1rem;
  margin-bottom: 5px;
}
#just-button {
  margin-top: 5px;
  padding: 5.5px 21px;
  font-size: 1rem;
  margin-bottom: 5px;
}

.scale-choice {
  padding: 0.5em!important;
}
.scale-choice.divider {
  box-shadow: 0px 2px 0px -1px rgba(34,36,38,.1) !important;
}



.tuning {
  margin-right: 25%;
}

.no-padding {
  padding: 0px!important;
}

#effects-settings-menu-container.no-padding {
  padding: 0px!important;
}

/* Effects Panel Buttons */

#effects-settings-menu-container .effect-module {
  padding: 16px 8px!important;
}

#effectSettings,
#effectSettingsWithHeight {
  padding: 4px 11px 1px 11px;
  margin: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 1.25rem;
  height: 26px;
}
#effectSettingsWithHeight {
  height: 40px;
}
#effectSwitch.ui.toggle.button,
#effectSwitch.ui.toggle.button.active {
  padding: 6px 21px 6px 11px;
  width: 116px;
  margin:1px 0px 1px 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: inset -1px 0 0 0 rgba(34,36,38,.15);
}


/* Don't think these effects-container css below are used anymore due to no dropdown */

.effects-container {
  display: flex;
  align-self: flex-start!important;
  /* width: 100%; */

}

.effects-stretch::before {
  display: none;
}

.effects-stretch {
  min-width: 28%;
  padding-bottom: 0px!important;
  display: flex!important;
  justify-content: flex-start!important;
}

.effects-stretch::after {
  display: none;
}

.effects-dropdown{
  margin-top: 2px;
}

.sound-close-menu {
  display: flex!important;
  justify-content: flex-end;
  align-items: center;
  right: 0.5em;
  position: absolute;
}

.sound-close-menu .button {
  margin: 0;
}

.horiz {
  display: flex;
  margin-top: 2%;
  align-items: center;
}

.vert {
  flex-direction: column;
  padding: inherit;
}

.extra-margin {
  margin: 15px 0px!important;
}

.no-line::before {
  display: none;
}

.ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #7CB342!important;
}

.ui.toggle.checkbox input:checked~label:before {
  background-color: #7CB342!important;
}
.tuning-container {
  /* width: 46%; */
  display: flex;
}

.tuning-close-menu{
  display: flex!important;
  justify-content: flex-end;
  align-items: center;
}
#edit-scales-button-tuning {
  /* position: absolute; */
  /* right: 10%; */
  /* bottom: 0%; */
  padding: 5.5px 21px;
  margin: -5px auto -3px auto;
}
.scales-bottom-tuning {
  width: 100%;
  display: flex;
  padding: 0.5em 0.5em;
  align-items: center;
}

.volume-container {
    position: absolute!important;
    /* right: 362.17px!important; */
}

.volume-controls {
    justify-content: center;
    padding-left: 5px!important;
    padding-right: 5px!important;
}

.volume-close-menu {
    position: absolute;
}
.scales-container {
  justify-content: center;
}

#scaleToggleButton {
  margin: 0;
  font-size: 1rem;
}

.ui.tabular.menu-menu .item.scales-container {
  padding: 0.1em 0.5em;
}

.ui.tabular.menu-menu .scales-container .item.edit-scales-container {
  display: flex!important;
  justify-content: space-around;
  flex-direction: row;
  z-index: 100;
  padding: 0.5em 0px;
}

@media screen and (max-width: 1040px) {
  .ui.tabular.menu-menu .item.edit-scales-container {
    padding-left: 4px;
    padding-right: 4px;
  }
}

@media screen and (max-width: 970px) {
  .ui.dropdown i.icon.dropdown {
    margin-left: 4px;
  }
}

.note {
  border: solid black;
  border-width: 0px;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  font-size: 1em;
  -webkit-user-select: none;
          user-select: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: lightgrey;
}

.root {
  border: solid black;
  border-width: 0;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  font-size: 1em;
  -webkit-user-select: none;
          user-select: none;
  padding: 10px;
  cursor: pointer;
  box-shadow: inset 0px 0px 0px 2px #56caff;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.activeNote {
  background-color: #7BB242;
}
.custom {
  box-shadow: inset 0px 0px 0px 2px rgb(255, 168, 5);
}

/* filter.css */

.filter-canvas {
    z-index: 51;
    position: relative;
    /* width: 33vw;
    height: 33vh;
    margin: 1vh 1vw;
    position: absolute; */
    top: 0%;
    background-color: lightblue;
    /* Firefox */
    image-rendering: -webkit-crisp-edges;
    /* Webkit (Safari) */
    image-rendering: pixelated;
    /* Chrome */
    flex-shrink: 0;
    /* Prevent canvas from shrinking */
    height: 35vh;
    /* Set fixed height */
    width: 100%;
    /* Ensure canvas takes full width */
}

.canvas-container {
    flex-shrink: 0;
    /* Prevent container from shrinking */
}

.edit-filter-container {
    width: 35vw;
    /* height: 35vh; */
    /* Remove height to allow content to adjust */
    display: flex !important;
    flex-direction: column;
    z-index: 50;
}

#FilterPresets {
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 5.5px 10.5px;
}
