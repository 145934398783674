.menu-pane-container {
  padding: 0;
  margin: 0!important;
  border: 0!important;
}

/* @media screen and (min-width: 1215px)  {
  .multi-slider-container {
    width: 200px;
  }
}
@media screen and (max-width: 1215px)  {
  .multi-slider-container {
      width: 150px;
  }
}
@media screen and (max-width: 1165px)  {
  .multi-slider-container {
      width: 110px;
  }
} */

.multi-slider-container {
  width: 12vw;
  max-width: 200px;
  padding-left: 2px;
}


.graph-control-menu-container {
  padding-left: 0px!important;
  padding-right: 5px!important;
}

.multi-slider {
  width: 80%!important;
  margin: 12px 15px 15px 15px;
  display: flex;
  /* padding: 0!important; */
  height: 14px !important;
  transform: translateX(-4px)
}

/* .graph-limit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  padding: 0px 5px!important;
} */

.multi-slider * {
  height: 12px!important;
}

.rc-slider-handle {
  width: 30px!important;
  height: 30px!important;
  top: 50%!important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
  border: 1px solid #ccc!important;
  transform: translate3d(-50%, -30%, 0)!important;
  margin: 0!important;
}
.rc-slider-handle::after {
  content: ' ';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  background-color: #dadada;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
}
/* .rc-slider-handle:hover {
  background-color: white;
}
.rc-slider-handle:active {
  box-shadow: 0 0 5px #d8ff7b!important;
} */

.rc-slider-track {
  background-color: #7CB342!important;
}
.rc-slider-step {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 10px !important;
}
.rc-slider {
  height: 0px!important;
  padding: 2px 0!important;
}

.resolution-input-container {
  margin-top: 20%; 
}

.resolution-container {
display: flex;
justify-content: center;
align-items: center;
}

.resolution-input {
  width: 40%;
  margin: 0!important;
}

.graph-preset-container {
  display: flex;
  flex-direction: column; 
}

.graph-preset-container .ui.radio.checkbox {
  padding-top: 2px;
  padding-bottom: 2px;
}

.graph-container {
  /* position: absolute!important; */
}

#logScaleBtn {
  font-size: 1rem!important;
}
