.menu-container {
  position: absolute;
  left: 0%;
}

.menu-title {
  font-size: 24px;
  align-self: center;
  text-align: center;
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  user-select: none;
}

.menu-header {
  font-weight: 700;
  font-size: 14px;
}

.ui.tabular.menu .item {
  background: 0 0;
  border-bottom: none;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 2px solid transparent;
  padding: .1em 1.42857143em;
  color: rgba(0,0,0,.87);
}

.menu-menu {
  height: 105px;
  /* min-height: 90px;
  max-height: 10vh; */
  margin: 0!important;
  border-bottom: 1px solid black!important;
  width: 100vw!important;
}

.menu-container .menu-menu.menu .item.tab-item {
  border-left-color: rgba(0,0,0,.1);
  border-right-color: rgba(0,0,0,.1);
}

.menu-container .menu-menu.menu .item.tab-item.active {
  box-shadow: inset 0px 0px 5px 2px rgba(34,36,38,.1);
}

@media screen and (max-width: 970px) {
  .menu-container .item.tab-item {
    padding-left: 2px!important;
    padding-right: 2px!important;
  }
}

@media screen and (max-width: 1241px) {
  .ui.tabular.menu-menu.menu .item.scale-menu {
    padding: 0px!important;
  }
}

@media screen and (max-width: 1040px) {
  .menu-container .ui.tabular.menu .item.tab-item {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.ui.tabular.menu .item.tab-item.settings-ctrl-btn {
  padding-left: 10px;
  padding-right: 10px;
}

.rangeslider__handle {
  outline: none;
}

.slider {
  min-width: 100px;
}

.flex {
  display: flex;
  flex-direction: column;
}

.item {
  font-size: 16px!important;
}

.icon-button {
  background: none!important;
}

/* .function-switch-button-container {
  padding-left: 0!important;
}

.function-switch-button {
  border: 2px solid #9ad5f7;
  outline: none;
  border-radius: .28571429rem;
  padding: .5em 1.2em .5em;
  line-height: 1em;
  background-color: white;
  color: #9ad5f7;
  font-weight: 700;
  font-style: normal;
  font-size: 1em;
  min-height: 1em;
  vertical-align: baseline;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}

.function-switch-button:hover {
  outline: none;
  cursor: pointer;
}

.function-switch-button:active {
  background-color: #49a3cc;
  border: 2px solid #49a3cc;
  color: white;
  outline: none;
  cursor: pointer;
}

.tuning-button {
  border: 2px solid #ff8177;
  outline: none;
  border-radius: .28571429rem;
  padding: .5em 1.2em .5em;
  line-height: 1em;
  background-color: white;
  color: black;
  font-weight: 400;
  line-height: 1;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 16px;
  min-height: 1em;
  vertical-align: baseline;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  user-select: none;
} */

/* .freq-button {
  border: 2px solid #ff695e;
  outline: none;
  border-radius: .28571429rem;
  padding: .5em 1.2em .5em;
  line-height: 1em;
  background-color: white;
  color: #ff695e;
  font-weight: 700;
  font-style: normal;
  font-size: 1em;
  min-height: 1em;
  vertical-align: baseline;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}

.freq-button:hover {
  outline: none;
  cursor: pointer;
}

.freq-button:active {
  background-color: #ff8177;
  border: 2px solid #ff8177;
  color: white;
  outline: none;
  cursor: pointer;
} */

/* .freq-button {
  background-color: white!important;
  color: black!important;
  font-weight: 400!important;

} */

/* .tuning-button:hover {
  outline: none;
  cursor: pointer;
} */

.ui.slider.checkbox input:focus:checked~.box:before, .ui.slider.checkbox input:focus:checked~label:before {
  background-color: #ABE2FB!important;
}

.ui.slider.checkbox input:checked~.box:before, .ui.slider.checkbox input:checked~label:before {
  background-color: #ABE2FB!important;
}

.ui.slider.checkbox input:focus~.box:before, .ui.slider.checkbox input:focus~label:before {
  background-color: #7CB342!important;
}

.ui.slider.checkbox .box:before, .ui.slider.checkbox label:before {
  background-color: #7CB342!important;
}
.ui.toggle.button {
  vertical-align: middle;
}
.ui.toggle.button.active {
  /* margin-right: 6.325px;
  margin-left: 2.325px; */
  /* Active color */
  background-color: #7CB342!important;
}

.hidden {
  display: none;
}

.item.scale-menu .scale-adjust {
  box-shadow: 0px 0px 0px 1px rgba(34,36,38,.1)!important;
  padding: 0.1em 0.25em;
  min-width: 196px;
}
@media screen and (max-width: 970px) {
  .ui.menu .item.scale-menu .scale-adjust {
    padding: 0px!important;
  }
}


.no-margin {
  margin: 0!important;
  padding: 0!important;
}



/* @media screen and (max-width: 1200px)  {
  .microphone-positioning {
      left: 15%!important;

  }
}

.microphone-positioning {
  position: absolute;
  left: 25%;
} */

/* .app-bar-overlay{
  position: absolute;
  z-index: 40;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0%;
  top: 0%;
}

.app-bar-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 300px;
  z-index: 50;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
  0px 16px 24px 2px rgba(0, 0, 0, 0.14),
  0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.app-bar-title-container {
  height: 15vh;
  background-color: white;
  display: flex;
  align-items: center;
  position: relative;
}

.app-bar-title {
  padding-left: 1rem;
  text-align: left;
  font-size: 20px;
  color: grey;
}

.app-bar-about {
  position: absolute;
  right: 2%;
  bottom: 2%;
}

.app-bar-hr {
  height: 1px;
  margin: 0;
  border: none; 
  background-color: rgba(0, 0, 0, 0.12);
}

.app-bar-buttons-container {
  display: flex;
  flex-direction:column;
  width: 300px;
  margin-top: 2%;
}

.app-bar-button {
  text-align: left;
  width: 300px;
  margin-bottom: 1rem;
  cursor: pointer;
  padding: 1rem 1rem;
  color: black;
  font-size: 2em;
  outline: none;
  line-height: 1em;
  background-color: white;
  border: 0px;
}

.app-bar-button:hover {
  background-color: #F5F5F5;
}

.app-bar-chevron {
  position: absolute;
  right: 0%;
} */
.app-bar-dropdown-container {
  padding-left: 0px !important;
  z-index: 40;
  padding-right: 0px!important
}

.app-bar-dropdown {
  font-size: 1.25rem;
  min-width: 9rem!important;
  border: none!important;
  min-height: 1rem!important;
  user-select: none;
  font-variant: small-caps;
  font-weight: 700!important;
}

@media screen and (max-width: 1040px) {
  .ui.selection.dropdown.app-bar-dropdown {
    padding-left: 4px!important;
    padding-right: 0px!important;
  }
  .ui.selection.dropdown.app-bar-dropdown>i.dropdown.icon {
    padding-left: 4px!important;
    padding-right: 4px!important;
  }
}

/* .app-bar-dropdown-menu {
  height: 225%;
  border: none!important;
} */

.ui.selection.active.dropdown .menu {
  border: none !important;
}

.slider-label {
  margin-right: 0.5rem;
}


